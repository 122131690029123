<template>
  <v-container>
    <v-card flat class="rounded-lg mb-2">
      <v-card-title>
        <v-icon left color="primary">mdi-calendar</v-icon>
        Mis Horarios
      </v-card-title>
    </v-card>
    <v-toolbar flat class="rounded-lg mb-2">
      <v-btn icon @click="prev">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title class="text-capitalize">
        {{ currentMonthName }} {{ currentYear }}
      </v-toolbar-title>
      <v-btn icon @click="next" class="ml-4">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        small
        @click="refreshCalendar"
        class="mr-2"
        :loading="loading"
        fab
        elevation="0"
      >
        <v-icon>mdi-refresh</v-icon>
        
      </v-btn>
    </v-toolbar>

    <v-sheet height="600" class="rounded-lg overflow-hidden">
      <v-calendar ref="calendar" v-model="focus" type="month" :events="calendarEvents" :event-color="getEventColor" @click:event="showEvent">
        <template v-slot:event="{ event }">
          <div class="d-flex align-center px-1">
            <v-icon small left dark class="mr-1">
              mdi-clock-outline
            </v-icon>
            {{ formatEventTime(event) }}
            <v-chip v-if="event.details.breakDuration" x-small dark class="ml-2" color="secondary ">
              {{ event.details.breakDuration }}'' descanso
            </v-chip>
          </div>
        </template>
      </v-calendar>
    </v-sheet>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    
  <!--   <div v-if="userSchedule.length === 0 && !loading" class="text-center pa-4">
      <v-alert type="info">
        No shifts scheduled for this month.
      </v-alert>
      <v-btn color="primary" @click="createSampleSchedule" class="mt-4">
        Create Sample Schedule (Testing Only)
      </v-btn>
    </div> -->
    
    <div v-if="error" class="text-center pa-4">
      <v-alert type="error" dismissible>
        {{ error }}
      </v-alert>
    </div>
    
    <!-- Event details dialog -->
    <v-dialog v-model="eventDialog.show" max-width="500" class="rounded-lg">
      <v-card>
        <v-card-title>
          Detalles del Turno
          <v-spacer></v-spacer>
          <v-btn icon @click="eventDialog.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider/>
        
        <v-card-text v-if="eventDialog.event" class="mt-4">
          <v-list dense>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="primary">mdi-calendar</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Fecha</v-list-item-title>
                <v-list-item-subtitle>{{ formatEventDate(eventDialog.event) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon color="primary">mdi-clock-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Horario</v-list-item-title>
                <v-list-item-subtitle>{{ formatEventTime(eventDialog.event) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="eventDialog.event.details.breakDuration">
              <v-list-item-icon>
                <v-icon color="amber darken-2">mdi-food-fork-drink</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Tiempo de Descanso</v-list-item-title>
                <v-list-item-subtitle>{{ eventDialog.event.details.breakDuration }} minutos</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon color="primary">mdi-clock-time-four-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Duración del Turno</v-list-item-title>
                <v-list-item-subtitle>{{ calculateShiftDuration(eventDialog.event) }} horas</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider/>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="eventDialog.show = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
   <!--  <v-card v-if="userSchedule.length > 0" class="mt-4" outlined>
      <v-card-title>Debug Information</v-card-title>
      <v-card-text>
        <p><strong>Scheduled Days:</strong> {{ userSchedule.length }}</p>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Date</th>
                <th>Time Slots</th>
                <th>Break Duration</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(schedule, index) in userSchedule" :key="index">
                <td>{{ formatScheduleDate(schedule) }}</td>
                <td>
                  <div v-if="schedule.timeSlots && schedule.timeSlots.length">
                    <div v-for="(slot, slotIndex) in schedule.timeSlots" :key="slotIndex">
                      {{ slot.startTime }} - {{ slot.endTime }}
                    </div>
                  </div>
                  <div v-else>
                    {{ schedule.startTime || '09:00' }} - {{ schedule.endTime || '17:00' }}
                  </div>
                </td>
                <td>
                  <div v-if="schedule.timeSlots && schedule.timeSlots.length">
                    <div v-for="(slot, slotIndex) in schedule.timeSlots" :key="slotIndex">
                      {{ slot.breakDuration || schedule.breakDuration || 60 }} min
                    </div>
                  </div>
                  <div v-else>
                    {{ schedule.breakDuration || 60 }} min
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card> -->
  </v-container>
</template>

<script>
import moment from 'moment';
import workforceService from '@/services/workforceService';
import { getFirestore, collection, addDoc, serverTimestamp, Timestamp } from 'firebase/firestore';

const db = getFirestore();

export default {
  name: 'WorkforceCalendar',
  data() {
    return {
      focus: moment().format('YYYY-MM-DD'),
      userSchedule: [],
      loading: true,
      error: null,
      debounceTimer: null,
      eventDialog: {
        show: false,
        event: null
      }
    }
  },
  computed: {
    currentMonthName() {
      return moment(this.focus).locale('es').format('MMMM');
    },
    currentYear() {
      return moment(this.focus).format('YYYY');
    },
    calendarEvents() {
      console.log("Creating calendar events from:", this.userSchedule);
      if (!this.userSchedule || !this.userSchedule.length) return [];

      return this.userSchedule.flatMap(schedule => {
        console.log("Processing schedule:", schedule);
        if (!schedule.date) {
          console.warn("Schedule missing date property:", schedule);
          return [];
        }

        // Check if date is a Firebase timestamp or a regular Date
        let date;
        if (typeof schedule.date.toDate === 'function') {
          date = schedule.date.toDate();
        } else if (schedule.date instanceof Date) {
          date = schedule.date;
        } else {
          console.warn("Unrecognized date format:", schedule.date);
          return [];
        }

        console.log("Schedule date:", date);

        // First check if we have timeSlots array
        if (schedule.timeSlots && Array.isArray(schedule.timeSlots) && schedule.timeSlots.length > 0) {
          console.log("Found timeSlots:", schedule.timeSlots);
          return schedule.timeSlots.map(slot => {
            // Ensure we have valid startTime and endTime
            const startTime = slot.startTime || '09:00';
            const endTime = slot.endTime || '17:00';
            
            return {
              name: 'Schedule',
              start: moment(date)
                .hour(parseInt(startTime.split(':')[0]))
                .minute(parseInt(startTime.split(':')[1]))
                .format('YYYY-MM-DD HH:mm'),
              end: moment(date)
                .hour(parseInt(endTime.split(':')[0]))
                .minute(parseInt(endTime.split(':')[1]))
                .format('YYYY-MM-DD HH:mm'),
              color: 'primary',
              timed: true,
              details: {
                ...schedule,
                startTime,
                endTime,
                breakDuration: slot.breakDuration || schedule.breakDuration || 60
              }
            };
          });
        } else {
          // Fall back to startTime and endTime on the schedule itself
          const startTime = schedule.startTime || '09:00';
          const endTime = schedule.endTime || '17:00';
          
          console.log("Using schedule level times:", startTime, endTime);
          
          return [{
            name: 'Schedule',
            start: moment(date)
              .hour(parseInt(startTime.split(':')[0]))
              .minute(parseInt(startTime.split(':')[1]))
              .format('YYYY-MM-DD HH:mm'),
            end: moment(date)
              .hour(parseInt(endTime.split(':')[0]))
              .minute(parseInt(endTime.split(':')[1]))
              .format('YYYY-MM-DD HH:mm'),
            color: 'primary',
            timed: true,
            details: {
              ...schedule,
              startTime,
              endTime,
              breakDuration: schedule.breakDuration || 60
            }
          }];
        }
      });
    }
  },
  methods: {
    prev() {
      if (this.loading) return;

      const newDate = moment(this.focus).subtract(1, 'month').startOf('month');
      this.focus = newDate.format('YYYY-MM-DD');
      this.debouncedFetch();
    },
    next() {
      if (this.loading) return;

      const newDate = moment(this.focus).add(1, 'month').startOf('month');
      this.focus = newDate.format('YYYY-MM-DD');
      this.debouncedFetch();
    },
    debouncedFetch() {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }

      this.debounceTimer = setTimeout(() => {
        this.fetchUserSchedule();
      }, 300);
    },
    formatEventTime(event) {
      if (event.details.startTime && event.details.endTime) {
        return `${event.details.startTime} - ${event.details.endTime}`;
      }
      return moment(event.start).format('HH:mm') + ' - ' + moment(event.end).format('HH:mm');
    },
    formatEventDate(event) {
      return moment(event.start).locale('es').format('dddd, D [de] MMMM, YYYY');
    },
    calculateShiftDuration(event) {
      if (!event.start || !event.end) return '0';
      
      const start = moment(event.start);
      const end = moment(event.end);
      const durationMinutes = end.diff(start, 'minutes');
      const hours = Math.floor(durationMinutes / 60);
      const minutes = durationMinutes % 60;
      
      if (minutes === 0) {
        return `${hours}`;
      } else {
        return `${hours}.${minutes}`;
      }
    },
    formatScheduleDate(schedule) {
      if (!schedule.date) return 'Invalid date';
      
      let date;
      if (typeof schedule.date.toDate === 'function') {
        date = schedule.date.toDate();
      } else if (schedule.date instanceof Date) {
        date = schedule.date;
      } else {
        return 'Invalid date format';
      }
      
      return moment(date).format('YYYY-MM-DD');
    },
    getEventColor(event) {
      // You can customize colors based on event type or properties
      if (event.details.isUnscheduled) {
        return 'orange';
      }
      return event.color || 'primary';
    },
    showEvent({ event }) {
      // Set the event to display in the dialog
      this.eventDialog.event = event;
      this.eventDialog.show = true;
    },
    async fetchUserSchedule() {
      this.loading = true;
      this.error = null;

      try {
        const currentDate = moment(this.focus);
        const month = currentDate.month();
        const year = currentDate.year();

        console.log(`Fetching schedule for ${month}/${year}`);
        this.userSchedule = await workforceService.getUserSchedule(month, year);
        console.log(`Found ${this.userSchedule.length} schedules:`, this.userSchedule);
      } catch (error) {
        console.error('Error fetching user schedule:', error);
        this.error = 'Failed to load schedule. Please try again.';
        this.userSchedule = [];
      } finally {
        this.loading = false;
      }
    },
    refreshCalendar() {
      this.fetchUserSchedule();
    },
    async createSampleSchedule() {
      this.loading = true;
      
      try {
        // Get the current user ID from auth
        const auth = this.$store.state.Auth;
        const user = auth.token.claims.user_id;
        
        if (!user) {
          throw new Error('User not authenticated');
        }
        
        // Create sample schedule for current month
        const currentDate = moment(this.focus);
        const month = currentDate.month();
        const year = currentDate.year();
        
        // Create a schedule for today
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        const scheduleData = {
          userId: user,
          date: Timestamp.fromDate(today),
          month: month,
          year: year,
          timeSlots: [
            {
              startTime: '09:00',
              endTime: '13:00',
              breakDuration: 30
            },
            {
              startTime: '14:00',
              endTime: '18:00',
              breakDuration: 30
            }
          ],
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        };
        
        // Add to Firestore
        await addDoc(collection(db, 'schedulesConfig'), scheduleData);
        
        // Refresh the calendar
        this.fetchUserSchedule();
        
      } catch (error) {
        console.error('Error creating sample schedule:', error);
        this.error = `Failed to create sample schedule: ${error.message}`;
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    console.log("WorkforceCalendar component mounted");
    this.debouncedFetch();
  },
  beforeDestroy() {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
  }
}
</script>

<style scoped>
:deep(.v-calendar-weekly__head-weekday) {
  border-right: thin solid rgba(224, 224, 224, 0.1) !important;
  border-bottom: thin solid rgba(224, 224, 224, 0.1) !important;
}

:deep(.v-calendar-weekly__day) {
  border-right: thin solid rgba(224, 224, 224, 0.1) !important;
  border-bottom: thin solid rgba(224, 224, 224, 0.1) !important;
}

:deep(.v-calendar-weekly) {
  border: thin solid rgba(224, 224, 224, 0.1) !important;
}


/* Add these new styles */
.theme--dark :deep(.v-calendar-weekly__head-weekday) {
    border-right: 1px solid #e0e0e02a !important;
    border-bottom: 1px solid #e0e0e02a !important;
}

.theme--dark :deep(.v-calendar-weekly__day) {
    border-right: 1px solid #e0e0e02a !important;
    border-bottom: 1px solid #e0e0e02a !important;
}

.theme--dark :deep(.v-calendar-weekly) {
    border: 1px solid #e0e0e02a !important;
    border-bottom: none !important;
}

.event-menu {
    border-radius: 8px;
    overflow: hidden;
}



.theme--dark :deep(.v-calendar-daily__intervals-head) {
    border-right: #e0e0e02a 1px solid !important;
}

.theme--dark :deep(.v-calendar-daily_head-day) {
    border-right: #e0e0e02a 1px solid !important;
    border-bottom: #e0e0e02a 1px solid !important;
    color: #FFFFFF;
}

.theme--dark :deep(.v-calendar-daily__day-interval) {
    border-top: #e0e0e02a 1px solid !important;
}

.theme--dark :deep(.v-calendar-daily__day) {
    border-right: #e0e0e02a 1px solid !important;
    border-bottom: #e0e0e02a 1px solid !important;
}


.theme--dark :deep(.v-calendar-daily) {
    border-left: #e0e0e02a 1px solid !important;
    border-top: #e0e0e02a 1px solid !important;
}
</style> 