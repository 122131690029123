import { getFirestore, collection, addDoc, query, where, getDocs, doc, updateDoc, deleteDoc, orderBy, Timestamp, serverTimestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import store from '@/store/index.js';
import moment from 'moment';
const db = getFirestore();

export default {
  // Check-in functionality
  async recordCheckIn(locationData, photoUrl = null, override = false, isUnscheduled = false) {
    const auth = store.state.Auth
    const user = auth.token.claims.user_id
    
    if (!user) throw new Error('User not authenticated');
    
    try {
      // Check if user already has an ACTIVE check-in (one without a checkout)
      const activeCheckInQuery = query(
        collection(db, 'attendance'),
        where('userId', '==', user),
        where('date', '>=', moment().startOf('day').toDate()),
        where('date', '<=', moment().endOf('day').toDate()),
        where('checkOutTime', '==', null)
      );
      
      const activeCheckInSnapshot = await getDocs(activeCheckInQuery);
      
      if (!activeCheckInSnapshot.empty) {
        throw new Error('You must check out from your current shift before checking in again');
      }
      
      // Get today's completed check-ins (with checkout)
      const completedCheckInsQuery = query(
        collection(db, 'attendance'),
        where('userId', '==', user),
        where('date', '>=', moment().startOf('day').toDate()),
        where('date', '<=', moment().endOf('day').toDate()),
        where('checkOutTime', '!=', null)
      );
      
      const completedCheckInsSnapshot = await getDocs(completedCheckInsQuery);
      const completedShifts = completedCheckInsSnapshot.docs.length; // Use docs.length for accurate count
      
      console.log(`User has completed ${completedShifts} shifts today`);
      
      let scheduleData = null;
      let timeSlots = [];
      let breakDuration = 60; // Default break duration in minutes
      
      if (!isUnscheduled) {
        // Get today's schedule
        const todayStart = moment().startOf('day').toDate();
        const todayEnd = moment().endOf('day').toDate();
        
        const todayScheduleQuery = query(
          collection(db, 'schedulesConfig'),
          where('userId', '==', user),
          where('date', '>=', todayStart),
          where('date', '<=', todayEnd)
        );
        
        const todayScheduleSnapshot = await getDocs(todayScheduleQuery);
        
        if (!todayScheduleSnapshot.empty) {
          scheduleData = todayScheduleSnapshot.docs[0].data();
          timeSlots = scheduleData.timeSlots || [{
            startTime: scheduleData.startTime || '09:00',
            endTime: scheduleData.endTime || '17:00',
            breakDuration: scheduleData.breakDuration || 60
          }];
          
          // Get break duration from the next slot if available
          if (completedShifts < timeSlots.length) {
            breakDuration = timeSlots[completedShifts].breakDuration || 60;
          }
          
          console.log(`User has ${timeSlots.length} time slots scheduled for today`);
          
          // Check if completed shifts exceeds scheduled time slots, but still allow check-in
          if (completedShifts >= timeSlots.length && !isUnscheduled) {
            console.log('User has completed all scheduled shifts but is checking in again');
            // We'll allow this but mark it as unscheduled
            isUnscheduled = true;
          }
          
          // Check if trying to check in too early for next shift (unless override is true)
          if (!override && completedShifts < timeSlots.length) {
            const nextSlot = timeSlots[completedShifts];
            const nextStartParts = nextSlot.startTime.split(':');
            const nextStartHour = parseInt(nextStartParts[0]);
            const nextStartMinute = parseInt(nextStartParts[1]);
            
            const nextShiftStart = moment().startOf('day')
              .add(nextStartHour, 'hours')
              .add(nextStartMinute, 'minutes');
            
            const currentTime = moment();
            const minutesEarly = nextShiftStart.diff(currentTime, 'minutes');
            
            if (minutesEarly > 15) {
              throw new Error(`Your next shift begins at ${nextSlot.startTime}. You're ${minutesEarly} minutes early. Please wait until closer to your scheduled start time.`);
            }
          }
        } else {
          console.log('No schedule found for today, allowing unscheduled check-in');
          isUnscheduled = true;
        }
      }
      
      // Create new attendance record
      const attendanceData = {
        userId: user,
        userName: auth.token.claims.name || '',
        date: serverTimestamp(),
        checkInTime: serverTimestamp(),
        checkOutTime: null,
        lunchStartTime: null,
        lunchEndTime: null,
        checkInLocation: locationData,
        checkInPhoto: photoUrl,
        checkOutLocation: null,
        checkOutPhoto: null,
        totalWorkHours: 0,
        status: 'Working',
        isEarlyCheckin: override ? true : false,
        isUnscheduled: isUnscheduled,
        breakDuration: breakDuration,
        slotIndex: completedShifts,
        scheduledShift: !isUnscheduled && scheduleData ? {
          startTime: completedShifts < timeSlots.length ? timeSlots[completedShifts].startTime : null,
          endTime: completedShifts < timeSlots.length ? timeSlots[completedShifts].endTime : null,
          breakDuration: breakDuration
        } : null
      };
      
      // Create an admin notification for unscheduled check-ins
      if (isUnscheduled) {
        try {
          const notificationData = {
            type: 'unscheduled_checkin',
            userId: user,
            userName: auth.token.claims.name || '',
            message: `${auth.token.claims.name || 'A user'} checked in without a scheduled shift.`,
            createdAt: serverTimestamp(),
            status: 'unread',
            priority: 'medium'
          };
          
          //await addDoc(collection(db, 'adminNotifications'), notificationData);
          console.log('Admin notification created for unscheduled check-in');
        } catch (notifError) {
          console.error('Failed to create admin notification:', notifError);
          // Don't block check-in if notification fails
        }
      }
      
      const docRef = await addDoc(collection(db, 'attendance'), attendanceData);
      return { id: docRef.id, ...attendanceData };
    } catch (error) {
      console.error('Error recording check-in:', error);
      throw error;
    }
  },
  
  // Start lunch break
  async startLunch() {
    const auth = store.state.Auth
    const user = auth.token.claims.user_id  
    
    if (!user) throw new Error('User not authenticated');
    
    try {
      // Find today's active attendance record
      const activeCheckInQuery = query(
        collection(db, 'attendance'),
        where('userId', '==', user),
        where('date', '>=', moment().startOf('day').toDate()),
        where('date', '<=', moment().endOf('day').toDate()),
        where('checkOutTime', '==', null)
      );
      
      const activeCheckInSnapshot = await getDocs(activeCheckInQuery);
      
      if (activeCheckInSnapshot.empty) {
        throw new Error('No active check-in found for today');
      }
      
      // Update the record with lunch start time
      const attendanceDoc = activeCheckInSnapshot.docs[0];
      const attendanceRef = doc(db, 'attendance', attendanceDoc.id);
      
      await updateDoc(attendanceRef, {
        lunchStartTime: Timestamp.now(),
        status: 'On Lunch'
      });
      
      return { 
        id: attendanceDoc.id, 
        ...attendanceDoc.data(), 
        lunchStartTime: Timestamp.now(),
        status: 'On Lunch'
      };
    } catch (error) {
      console.error('Error starting lunch:', error);
      throw error;
    }
  },
  
  // End lunch break
  async endLunch() {
    const auth = store.state.Auth
    const user = auth.token.claims.user_id
    
    if (!user) throw new Error('User not authenticated');
    
    try {
      // Find today's active attendance record
      const activeCheckInQuery = query(
        collection(db, 'attendance'),
        where('userId', '==', user),
        where('date', '>=', moment().startOf('day').toDate()),
        where('date', '<=', moment().endOf('day').toDate()),
        where('checkOutTime', '==', null),
        where('lunchStartTime', '!=', null)
      );
      
      const activeCheckInSnapshot = await getDocs(activeCheckInQuery);
      
      if (activeCheckInSnapshot.empty) {
        throw new Error('No active lunch break found');
      }
      
      // Update the record with lunch end time
      const attendanceDoc = activeCheckInSnapshot.docs[0];
      const attendanceRef = doc(db, 'attendance', attendanceDoc.id);
      
      await updateDoc(attendanceRef, {
        lunchEndTime: Timestamp.now(),
        status: 'Working'
      });
      
      return { 
        id: attendanceDoc.id, 
        ...attendanceDoc.data(), 
        lunchEndTime: Timestamp.now(),
        status: 'Working'
      };
    } catch (error) {
      console.error('Error ending lunch:', error);
      throw error;
    }
  },
  
  // Check-out functionality
  async recordCheckOut(locationData, photoUrl = null) {
    const auth = store.state.Auth
    const user = auth.token.claims.user_id
    
    if (!user) throw new Error('User not authenticated');
    
    try {
      // Find today's active attendance record
      const activeCheckInQuery = query(
        collection(db, 'attendance'),
        where('userId', '==', user),
        where('date', '>=', moment().startOf('day').toDate()),
        where('date', '<=', moment().endOf('day').toDate()),
        where('checkOutTime', '==', null)
      );
      
      const activeCheckInSnapshot = await getDocs(activeCheckInQuery);
      
      if (activeCheckInSnapshot.empty) {
        throw new Error('No active check-in found for today');
      }
      
      // Update the record with check-out time
      const attendanceDoc = activeCheckInSnapshot.docs[0];
      const attendanceRef = doc(db, 'attendance', attendanceDoc.id);
      const checkOutTime = Timestamp.now();
      
      // Calculate total work hours
      const attendanceData = attendanceDoc.data();
      const checkInTime = attendanceData.checkInTime.toDate();
      const checkOutTimeDate = checkOutTime.toDate();
      
      let totalWorkMs = checkOutTimeDate - checkInTime;
      
      // Subtract lunch time if applicable
      if (attendanceData.lunchStartTime && attendanceData.lunchEndTime) {
        const lunchStartTime = attendanceData.lunchStartTime.toDate();
        const lunchEndTime = attendanceData.lunchEndTime.toDate();
        totalWorkMs -= (lunchEndTime - lunchStartTime);
      } else if (attendanceData.lunchStartTime && !attendanceData.lunchEndTime) {
        // If lunch was started but not ended, end it now
        const lunchStartTime = attendanceData.lunchStartTime.toDate();
        totalWorkMs -= (checkOutTimeDate - lunchStartTime);
      }
      
      // Convert to hours
      const totalWorkHours = totalWorkMs / (1000 * 60 * 60);
      
      await updateDoc(attendanceRef, {
        checkOutTime: checkOutTime,
        checkOutLocation: locationData,
        checkOutPhoto: photoUrl,
        totalWorkHours: parseFloat(totalWorkHours.toFixed(2)),
        status: 'Checked Out'
      });
      
      return { 
        id: attendanceDoc.id, 
        ...attendanceDoc.data(), 
        checkOutTime: checkOutTime,
        checkOutLocation: locationData,
        checkOutPhoto: photoUrl,
        totalWorkHours: parseFloat(totalWorkHours.toFixed(2)),
        status: 'Checked Out'
      };
    } catch (error) {
      console.error('Error recording check-out:', error);
      throw error;
    }
  },
  
  // Get today's attendance record
  async getTodayAttendance() {
    const auth = store.state.Auth
    const user = auth.token.claims.user_id
    
    if (!user) throw new Error('User not authenticated');
    
    try {
      const todayAttendanceQuery = query(
        collection(db, 'attendance'),
        where('userId', '==', user),
        where('date', '>=', moment().startOf('day').toDate()),
        where('date', '<=', moment().endOf('day').toDate())
      );
      
      const attendanceSnapshot = await getDocs(todayAttendanceQuery);
      
      if (attendanceSnapshot.empty) {
        return null;
      }
      
      const attendanceDoc = attendanceSnapshot.docs[0];
      return { id: attendanceDoc.id, ...attendanceDoc.data() };
    } catch (error) {
      console.error('Error getting today\'s attendance:', error);
      throw error;
    }
  },
  
  // Get user's attendance history
  async getUserAttendanceHistory(startDate, endDate) {
    const auth = store.state.Auth
    const user = auth.token.claims.user_id
    
    if (!user) throw new Error('User not authenticated');
    
    try {
      const attendanceQuery = query(
        collection(db, 'attendance'),
        where('userId', '==', user),
        where('date', '>=', startDate),
        where('date', '<=', endDate),
        orderBy('date', 'desc')
      );
      
      const attendanceSnapshot = await getDocs(attendanceQuery);
      
      return attendanceSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error('Error getting attendance history:', error);
      throw error;
    }
  },
  
  // Get user's schedule
  async getUserSchedule(month, year) {
    const auth = store.state.Auth
    const user = auth.token.claims.user_id
    
    if (!user) throw new Error('User not authenticated');
    
    try {
      console.log(`Fetching schedules for user ${user}, month: ${month}, year: ${year}`);
      
      // Create a query for the schedules in the specified month and year
      const scheduleQuery = query(
        collection(db, 'schedulesConfig'),
        where('userId', '==', user),
        where('month', '==', month),
        where('year', '==', year)
      );
      
      const scheduleSnapshot = await getDocs(scheduleQuery);
      console.log(`Found ${scheduleSnapshot.docs.length} schedule documents`);
      
      // If no schedules are found, try looking for schedules by date range instead
      if (scheduleSnapshot.empty) {
        console.log("No schedules found with month/year query, trying date range query");
        
        // Calculate the start and end dates for the month
        const startDate = new Date(year, month, 1);
        const endDate = new Date(year, month + 1, 0);
        
        console.log(`Date range query from ${startDate} to ${endDate}`);
        
        const dateRangeQuery = query(
          collection(db, 'schedulesConfig'),
          where('userId', '==', user),
          where('date', '>=', startDate),
          where('date', '<=', endDate)
        );
        
        const dateRangeSnapshot = await getDocs(dateRangeQuery);
        console.log(`Found ${dateRangeSnapshot.docs.length} schedule documents using date range`);
        
        return dateRangeSnapshot.docs.map(doc => {
          const data = doc.data();
          console.log(`Schedule document found: ${doc.id}`, data);
          return {
            id: doc.id,
            ...data
          };
        });
      }
      
      // Map the documents to their data
      return scheduleSnapshot.docs.map(doc => {
        const data = doc.data();
        console.log(`Schedule document found: ${doc.id}`, data);
        return {
          id: doc.id,
          ...data
        };
      });
    } catch (error) {
      console.error('Error getting user schedule:', error);
      throw error;
    }
  },
  
  // Helper function to get today's date as a string (YYYY-MM-DD)
  getTodayDateString() {
    const today = new Date();
    return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
  },
  
  // Helper method to check if the current user is an admin
  isAdmin() {
    const auth = store.state.Auth;
    return auth && auth.token && 
      (auth.token.claims.type === 'admin' || auth.token.claims.type === 'superuser');
  },

  // Add or update a schedule for a user
  async updateUserSchedule(userId, scheduleData) {
    if (!this.isAdmin()) throw new Error('Unauthorized');
    
    try {
      // Check if schedule already exists for this date
      const existingScheduleQuery = query(
        collection(db, 'schedulesConfig'),
        where('userId', '==', userId),
        where('date', '==', scheduleData.date)
      );
      
      const existingScheduleSnapshot = await getDocs(existingScheduleQuery);
      
      if (!existingScheduleSnapshot.empty) {
        // Update existing schedule
        const scheduleDoc = existingScheduleSnapshot.docs[0];
        
        // If we're just updating a single time slot
        if (scheduleData.slotIndex !== undefined) {
          const currentData = scheduleDoc.data();
          const timeSlots = [...(currentData.timeSlots || [])];
          
          if (scheduleData.slotIndex < timeSlots.length) {
            // Update existing slot
            timeSlots[scheduleData.slotIndex] = {
              startTime: scheduleData.startTime,
              endTime: scheduleData.endTime
            };
          } else {
            // Add new slot
            timeSlots.push({
              startTime: scheduleData.startTime,
              endTime: scheduleData.endTime
            });
          }
          
          await updateDoc(doc(db, 'schedulesConfig', scheduleDoc.id), {
            timeSlots,
            updatedAt: serverTimestamp()
          });
        } else {
          // Replace all time slots
          await updateDoc(doc(db, 'schedulesConfig', scheduleDoc.id), {
            timeSlots: [{
              startTime: scheduleData.startTime,
              endTime: scheduleData.endTime
            }],
            updatedAt: serverTimestamp()
          });
        }
        
        return {
          id: scheduleDoc.id,
          ...scheduleDoc.data(),
          timeSlots: scheduleData.timeSlots || [{
            startTime: scheduleData.startTime,
            endTime: scheduleData.endTime
          }]
        };
      } else {
        // Create new schedule
        const newScheduleData = {
          userId,
          date: scheduleData.date,
          timeSlots: scheduleData.timeSlots || [{
            startTime: scheduleData.startTime,
            endTime: scheduleData.endTime
          }],
          year: new Date(scheduleData.date).getFullYear(),
          month: new Date(scheduleData.date).getMonth(),
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        };
        
        const docRef = await addDoc(collection(db, 'schedulesConfig'), newScheduleData);
        return { id: docRef.id, ...newScheduleData };
      }
    } catch (error) {
      console.error('Error updating user schedule:', error);
      throw error;
    }
  },
  
  // Apply a schedule template to multiple days
  async applyScheduleTemplate(userId, template) {
    if (!this.isAdmin()) throw new Error('Unauthorized');
    
    try {
      const { days, startTime, endTime, startDate, endDate } = template;
      
      // Calculate all dates in the range that match the selected days of week
      const start = moment(startDate);
      const end = moment(endDate);
      const schedulePromises = [];
      
      for (let date = moment(start); date.isSameOrBefore(end); date.add(1, 'days')) {
        const dayOfWeek = date.day(); // 0 is Sunday, 1 is Monday, etc.
        
        if (days.includes(dayOfWeek)) {
          // This day of week is selected
          const scheduleData = {
            date: date.toDate(),
            startTime,
            endTime
          };
          
          schedulePromises.push(this.updateUserSchedule(userId, scheduleData));
        }
      }
      
      await Promise.all(schedulePromises);
      return { success: true, count: schedulePromises.length };
    } catch (error) {
      console.error('Error applying schedule template:', error);
      throw error;
    }
  },
  
  // Get all attendance records for admin view
  async getAllAttendanceRecords(startDate, endDate) {
    if (!this.isAdmin()) throw new Error('Unauthorized');
    
    try {
      // Convert string dates if necessary
      if (typeof startDate === 'string') startDate = new Date(startDate);
      if (typeof endDate === 'string') endDate = new Date(endDate);
      
      const attendanceQuery = query(
        collection(db, 'attendance'),
        where('date', '>=', startDate),
        where('date', '<=', endDate),
        orderBy('date', 'desc')
      );
      
      const attendanceSnapshot = await getDocs(attendanceQuery);
      
      return attendanceSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error('Error getting attendance records:', error);
      throw error;
    }
  },
  
  // Generate a workforce report
  async generateReport(reportType, startDate, endDate, users = []) {
    if (!this.isAdmin()) throw new Error('Unauthorized');
    
    try {
      // Convert string dates if necessary
      if (typeof startDate === 'string') startDate = new Date(startDate);
      if (typeof endDate === 'string') endDate = new Date(endDate);
      
      // Get attendance records
      let attendanceQuery;
      
      if (users.length > 0) {
        // Filter by selected users
        attendanceQuery = query(
          collection(db, 'attendance'),
          where('userId', 'in', users),
          where('date', '>=', startDate),
          where('date', '<=', endDate),
          orderBy('date', 'desc')
        );
      } else {
        // Get all users
        attendanceQuery = query(
          collection(db, 'attendance'),
          where('date', '>=', startDate),
          where('date', '<=', endDate),
          orderBy('date', 'desc')
        );
      }
      
      const attendanceSnapshot = await getDocs(attendanceQuery);
      
      // Process the data based on report type
      if (reportType === 'attendance') {
        // Basic attendance report
        return attendanceSnapshot.docs.map(doc => {
          const data = doc.data();
          
          // Format times
          const checkInTime = data.checkInTime ? data.checkInTime.toDate() : null;
          const checkOutTime = data.checkOutTime ? data.checkOutTime.toDate() : null;
          const lunchStartTime = data.lunchStartTime ? data.lunchStartTime.toDate() : null;
          const lunchEndTime = data.lunchEndTime ? data.lunchEndTime.toDate() : null;
          
          return {
            id: doc.id,
            userName: data.userName,
            userId: data.userId,
            date: data.date.toDate().toLocaleDateString(),
            checkIn: checkInTime ? checkInTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'N/A',
            checkOut: checkOutTime ? checkOutTime.toDate().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'N/A',
            lunchStart: lunchStartTime ? lunchStartTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'N/A',
            lunchEnd: lunchEndTime ? lunchEndTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'N/A',
            lunchDuration: lunchStartTime && lunchEndTime ? 
              `${Math.round((lunchEndTime - lunchStartTime) / (1000 * 60))} min` : 'N/A',
            totalHours: data.totalWorkHours ? data.totalWorkHours.toFixed(2) : 'N/A',
            status: data.status
          };
        });
      } else if (reportType === 'adherence') {
        // Schedule adherence report
        // Get schedules for the same period
        const scheduleQuery = query(
          collection(db, 'schedulesConfig'),
          where('date', '>=', startDate),
          where('date', '<=', endDate)
        );
        
        const scheduleSnapshot = await getDocs(scheduleQuery);
        const schedules = scheduleSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        // Match attendance with schedules
        return attendanceSnapshot.docs.map(doc => {
          const data = doc.data();
          const attendanceDate = data.date.toDate();
          
          // Find matching schedule
          const schedule = schedules.find(s => {
            const scheduleDate = s.date.toDate();
            return s.userId === data.userId && 
                   scheduleDate.getDate() === attendanceDate.getDate() &&
                   scheduleDate.getMonth() === attendanceDate.getMonth() &&
                   scheduleDate.getFullYear() === attendanceDate.getFullYear();
          });
          
          let adherence = 'N/A';
          let adherenceClass = 'neutral';
          
          if (schedule) {
            // Calculate schedule adherence for multiple time slots
            const timeSlots = schedule.timeSlots || [{
              startTime: schedule.startTime || '00:00',
              endTime: schedule.endTime || '00:00'
            }];
            
            const checkInTime = data.checkInTime ? data.checkInTime.toDate() : null;
            const checkOutTime = data.checkOutTime ? data.checkOutTime.toDate() : null;
            
            if (!checkInTime) {
              adherence = 'Missed';
              adherenceClass = 'bad';
            } else {
              // Find the earliest time slot for check-in comparison
              const earliestSlot = timeSlots.reduce((earliest, slot) => {
                const startParts = slot.startTime.split(':');
                const slotStartDate = new Date(attendanceDate);
                slotStartDate.setHours(parseInt(startParts[0]), parseInt(startParts[1]), 0, 0);
                
                const earliestStartParts = earliest.startTime.split(':');
                const earliestStartDate = new Date(attendanceDate);
                earliestStartDate.setHours(parseInt(earliestStartParts[0]), parseInt(earliestStartParts[1]), 0, 0);
                
                return slotStartDate < earliestStartDate ? slot : earliest;
              }, timeSlots[0]);
              
              // Find the latest time slot for check-out comparison
              const latestSlot = timeSlots.reduce((latest, slot) => {
                const endParts = slot.endTime.split(':');
                const slotEndDate = new Date(attendanceDate);
                slotEndDate.setHours(parseInt(endParts[0]), parseInt(endParts[1]), 0, 0);
                
                const latestEndParts = latest.endTime.split(':');
                const latestEndDate = new Date(attendanceDate);
                latestEndDate.setHours(parseInt(latestEndParts[0]), parseInt(latestEndParts[1]), 0, 0);
                
                return slotEndDate > latestEndDate ? slot : latest;
              }, timeSlots[0]);
              
              const earliestStartParts = earliestSlot.startTime.split(':');
              const scheduledStart = new Date(attendanceDate);
              scheduledStart.setHours(parseInt(earliestStartParts[0]), parseInt(earliestStartParts[1]), 0, 0);
              
              const latestEndParts = latestSlot.endTime.split(':');
              const scheduledEnd = new Date(attendanceDate);
              scheduledEnd.setHours(parseInt(latestEndParts[0]), parseInt(latestEndParts[1]), 0, 0);
              
              if (checkInTime > new Date(scheduledStart.getTime() + 15 * 60 * 1000)) {
                adherence = 'Late';
                adherenceClass = 'warning';
              } else if (!checkOutTime) {
                adherence = 'No Checkout';
                adherenceClass = 'warning';
              } else if (checkOutTime < new Date(scheduledEnd.getTime() - 15 * 60 * 1000)) {
                adherence = 'Left Early';
                adherenceClass = 'warning';
              } else {
                adherence = 'Good';
                adherenceClass = 'good';
              }
            }
          }
          
          return {
            id: doc.id,
            userName: data.userName,
            userId: data.userId,
            date: attendanceDate.toLocaleDateString(),
            checkIn: data.checkInTime ? data.checkInTime.toDate().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'N/A',
            checkOut: data.checkOutTime ? data.checkOutTime.toDate().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'N/A',
            lunchDuration: data.lunchStartTime && data.lunchEndTime ? 
              `${Math.round((data.lunchEndTime.toDate() - data.lunchStartTime.toDate()) / (1000 * 60))} min` : 'N/A',
            totalHours: data.totalWorkHours ? data.totalWorkHours.toFixed(2) : 'N/A',
            scheduledStart: schedule ? schedule.startTime : 'N/A',
            scheduledEnd: schedule ? schedule.endTime : 'N/A',
            adherence,
            adherenceClass
          };
        });
      } else {
        throw new Error('Invalid report type');
      }
    } catch (error) {
      console.error('Error generating report:', error);
      throw error;
    }
  },
  
  // Add this new method to workforceService.js
  async getAllTodayAttendance() {
    const auth = store.state.Auth
    const user = auth.token.claims.user_id
    
    if (!user) throw new Error('User not authenticated');
    
    try {
      // Query all attendance records for today, regardless of checkout status
      const attendanceQuery = query(
        collection(db, 'attendance'),
        where('userId', '==', user),
        where('date', '>=', moment().startOf('day').toDate()),
        where('date', '<=', moment().endOf('day').toDate()),
        orderBy('date', 'desc')
      );
      
      const attendanceSnapshot = await getDocs(attendanceQuery);
      
      return attendanceSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error('Error getting today\'s attendance:', error);
      throw error;
    }
  }
}; 